<template>
  <Panel aria-labelled-by="owner-zone-title"
         :close-modal-text="labels.closeModal"
         event-type="OwnerZone:showModal"
         @open="searchDocuments"
         content-css-class="pb-0">
    <div class="relative h-full">
      <transition-group :name="step < oldStep ? 'main-section' : 'secondary-section'">
        <div key="1" v-if="step === 1" class="h-full flex flex-col justify-between">
          <div>
            <div id="owner-zone-title" class="h4">{{ labels.title }}</div>
            <div class="mt-10 bg-gray-dark p-6">
              <div class="text-white text-lg">{{ labels.mainText }}</div>
              <div class="mt-6">
                <div v-if="!myVehicle">
                  <p class="mb-2">1. {{ labels.myVehicle }}</p>
                  <button class="p-4 bg-white heading text-black w-full rounded flex justify-between items-center"
                          type="button"
                          @click="step = 2">
                    <div class="flex gap-3 items-center">
                      <search-icon />
                      {{ labels.findVehicle }}
                    </div>
                    <arrow-icon class="-rotate-90" />
                  </button>
                </div>
                <div v-else class="bg-white text-black p-6 rounded">
                  <p class="mb-2 flex gap-3 items-center font-bold">
                    <vehicle-icon />
                    {{ labels.myVehicle }}
                  </p>
                  <p>
                    {{ myVehicle.join(" / ") }}
                  </p>
                  <button type="button" class="mt-2 link-underline link-underline-blue"
                          @click="step = 2">
                    {{ labels.changeVehicle }}
                  </button>
                </div>
              </div>

              <div class="mt-6">
                <div v-if="!myTrackSystem">
                  <p class="mb-2">2. {{ labels.myTrackSystem }}</p>
                  <button class="p-4 bg-white heading text-black w-full rounded flex justify-between items-center disabled:opacity-50"
                          :disabled="!myVehicle"
                          type="button"
                          @click="step = 3">
                    <div class="flex gap-3 items-center">
                      <search-icon />
                      {{ labels.findTrack }}
                    </div>
                    <arrow-icon class="-rotate-90" />
                  </button>
                </div>
                <div v-else class="bg-white text-black p-6 rounded">
                  <p class="mb-2 flex gap-3 items-center font-bold">
                    <track-icon />
                    {{ labels.myTrackSystem }}
                  </p>
                  <p>
                    {{ myTrackSystem.join(" / ") }}
                  </p>
                  <button type="button" class="mt-2 link-underline link-underline-blue"
                          @click="step = 2">
                    {{ labels.changeTrackSystem }}
                  </button>
                </div>
              </div>

              <div class="mt-14 flex flex-col gap-6">
                <div v-for="document in filteredDocuments">
                  <a class="arrow-link arrow-link-icon-gray arrow-link-text-white" :href="document.DocumentFullPath" target="_blank">
                    <span>{{ document.DocumentCategory }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 pb-10">
            <div class="mt-5" v-for="link in labels.footerLinks">
              <a class="link-underline link-underline-white" :href="link.url" target="_blank">
                {{ link.label }}
              </a>
            </div>
          </div>
        </div>
        <div class="pb-10" key="2" v-if="step === 2">
          <button
            class="arrow-link arrow-link-icon-gray-back arrow-link-text-white mb-10"
            @click="step = 1"
          >
            <span>{{ labels.back }}</span>
          </button>

          <Select
            v-for="cat in vehicleCategories"
            class="mb-6"
            :disabled="cat.list.value.length === 0"
            show-clear
            :placeholder="labels[cat.name]"
            v-model="cat.model.value"
            :options="cat.list.value"
            option-label="TagName"
            :option-value="cat.id"
            @update:modelValue="() => onModelChange(cat)"
          ></Select>

          <a class="mt-2 link-underline link-underline-white"
             :href="labels.cantFindVehicleLink"
             target="_blank">
            {{ labels.cantFindVehicleLabel }}
          </a>

          <primary-button css-class="btn-primary-blue btn-full mt-8 disabled:opacity-50"
                          :disabled="!myVehicle"
                          :label="labels.selectVehicle"
                          @click="step = 1" />
        </div>
        <div class="pb-10" key="3" v-if="step === 3">
          <button
            class="arrow-link arrow-link-icon-gray-back arrow-link-text-white mb-10"
            @click="step = 1"
          >
            <span>{{ labels.back }}</span>
          </button>

          <Select
            v-for="cat in trackSystemCategories"
            class="mb-6"
            :disabled="cat.list.value.length === 0"
            show-clear
            :placeholder="labels[cat.name]"
            v-model="cat.model.value"
            :options="cat.list.value"
            option-label="TagName"
            :option-value="cat.id"
            @update:modelValue="() => onModelChange(cat)"
          ></Select>

          <primary-button css-class="btn-primary-blue btn-full mt-2 disabled:opacity-50"
                          :disabled="!myTrackSystem"
                          :label="labels.selectTrackSystem"
                          @click="step = 1" />
        </div>
      </transition-group>
    </div>
  </Panel>
</template>

<script setup>
import { computed, ref, watch, onMounted } from 'vue';
import axios from 'axios';
import Panel from './Panel.vue';
import { capitalizeFirstLetter } from '../utils/string.js';
import Select from 'primevue/select';
import SearchIcon from '../../icons/search-box-blue.svg';
import VehicleIcon from '../../icons/vehicle.svg';
import TrackIcon from '../../icons/track.svg';
import ArrowIcon from '../../icons/dropdown-arrow.svg';
import PrimaryButton from './PrimaryButton.vue';

const step = ref(1);
const oldStep = ref(1);

watch(step, async (newStepValue, oldStepValue) => {
  oldStep.value = oldStepValue;
});

onMounted(() => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const ownerZoneParam = urlParams.get('ownerZone');
  if (ownerZoneParam) {
    const event = new CustomEvent("OwnerZone:showModal", { detail: { buttonElement: null } });
    document.dispatchEvent(event);
  }
});

const props = defineProps(['labels', 'language', 'selectorCategories', 'apiUrl']);

const documents = ref([]);

const categories = props.selectorCategories;
categories.forEach(c => {
  c.model = ref(null);
  c.list = ref([]);
});

const vehicleCategories = computed(() => {
  return categories.filter(c => c.vehicle);
});

const filteredDocuments = computed(() => {
  const documentCategories = new Set();
  return documents.value.reverse().filter(({ DocumentCategory }) => !documentCategories.has(DocumentCategory) && documentCategories.add(DocumentCategory));
});

const myVehicle = computed(() => {
  const myCategories = [];
  vehicleCategories.value.forEach(v => {
    const catValue = v.list.value.find(i => i[v.id] === v.model.value);
    if (catValue) {
      myCategories.push(catValue.TagName);
    }
  });
  return myCategories.length === vehicleCategories.value.length ? myCategories : null;
});

const trackSystemCategories = computed(() => {
  return categories.filter(c => c.trackSystem);
});

const myTrackSystem = computed(() => {
  const myCategories = [];
  trackSystemCategories.value.forEach(v => {
    const catValue = v.list.value.find(i => i[v.id] === v.model.value);
    if (catValue) {
      myCategories.push(catValue.TagName);
    }
  });
  return myCategories.length === trackSystemCategories.value.length ? myCategories : null;
});

const getCategoriesByName = (categories, name) => {
  return categories.find(c => c.CategoryName === name)?.Tags ?? [];
};

const setDefaultCategoryValue = (categories, category, id) => {
  if (categories.value.length === 1) {
    category.value = categories.value[0][id];
  }
};

const setCategorySearchParam = (url, paramName, category) => {
  if (category.value) {
    url.searchParams.set(paramName, category.value);
  }
};

const searchDocuments = async () => {
  const url = new URL(props.apiUrl);
  url.searchParams.set('lang', props.language);
  url.searchParams.set('docLanguage', props.language);
  categories.forEach(c => {
    setCategorySearchParam(url, c.name, c.model);
  });
  const res = await axios.get(url.toString());

  documents.value = res.data.Documents;

  categories.forEach(c => {
    c.list.value = getCategoriesByName(res.data.Categories, capitalizeFirstLetter(c.name));
    setDefaultCategoryValue(c.list, c.model, c.id);
  });
};

const onModelChange = (category) => {
  if (category.onClear && category.onClear.length > 0) {
    category.onClear.forEach(c => {
      categories.find(cat => cat.name === c).model.value = null;
    })
  }
  searchDocuments();
}

</script>

<style scoped>
.main-section-enter-active,
.main-section-leave-active,
.secondary-section-enter-active,
.secondary-section-leave-active {
  transition: all 0.5s ease;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.main-section-enter-from,
.main-section-leave-to,
.secondary-section-enter-from,
.secondary-section-leave-to {
  opacity: 0;
}
.main-section-enter-from {
  transform: translateX(-100%);
}
.main-section-leave-to {
  transform: translateX(100%);
}
.secondary-section-enter-from {
  transform: translateX(100%);
}
.secondary-section-leave-to {
  transform: translateX(-100%);
}
</style>
