import axios from 'axios';

export default function () {
  const form = document.querySelector('#newsletter-subscription');

  if (!form) return;

  const submitButton = form.querySelector('button');
  const newsletterError = document.querySelector('#newsletter-error');
  const newsletterSuccess = document.querySelector('#newsletter-success');

  form.addEventListener('submit', (e) => {
    e.preventDefault();
    submitButton.classList.add('loading');
    newsletterError.classList.add('hidden');
    const formData = new FormData(e.currentTarget);
    axios.postForm(form.getAttribute('action'), formData)
      .then((response) => {
        submitButton.classList.remove('loading');
        form.classList.add('hidden');
        newsletterSuccess.classList.remove('hidden');
        console.log(response);
      })
      .catch((error)  => {
        submitButton.classList.remove('loading');
        newsletterError.classList.remove('hidden');
        console.log(error);
      });
  });
}
