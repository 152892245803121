<template>
  <primary-button
    v-if="getCurrentStep < 4"
    css-class="btn-primary-blue btn-full"
    @click="formGoNextStep()"
    :label="label ? label : getLabels.next"
    :disabled="buttonNextStepIsDisabled"
  />
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import PrimaryButton from '../PrimaryButton.vue';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('transferKitAssistant');

export default {
  components: {
    PrimaryButton
  },
  props: {
    label: null,
  },
  computed: {
    ...mapGetters([
      'getLabels',
      'getCurrentStep',
      'getFormStepSerialNumberValidation',
      'getFormStepVehicleFromValidation',
      'getFormStepVehicleToValidation',
      'getSerialNumberSelected'
    ]),
    buttonNextStepIsDisabled () {
      if (this.getCurrentStep === 1) {
        return !this.getFormStepSerialNumberValidation;
      } else if (this.getCurrentStep === 2) {
        return !this.getFormStepVehicleFromValidation;
      } else if (this.getCurrentStep === 3) {
        return !this.getFormStepVehicleToValidation;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapMutations([
      'setCurrentStep',
      'setVehicleType',
      'resetStepVehicleFrom',
      'resetStepVehicleTo'
    ]),
    ...mapActions(['updateCaseCamsoX4S']),
    formGoNextStep () {
      let nextStep = this.getCurrentStep + 1;
      let stepInfo = 'undefined';

      switch (nextStep) {
        case 2:
          this.resetStepVehicleFrom();
          this.resetStepVehicleTo();
          this.setVehicleType(this.getSerialNumberSelected.type);
          this.updateCaseCamsoX4S();
          stepInfo = 'from';
          break;
        case 3:
          this.resetStepVehicleTo();
          stepInfo = 'to';
          break;
      }

      this.setCurrentStep(nextStep);
    }
  }
};
</script>
