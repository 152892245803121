export default function() {
  const buttons = document.querySelectorAll('.menu-button');
  let headerHeight = 0;

  buttons.forEach((button) => {
    const target = button.getAttribute("aria-controls");
    const submenu = document.getElementById(target);
    const ulWrapper = submenu.querySelector(".ul-wrapper");

    button.addEventListener('click', () => {
      headerHeight = document.querySelector('header').offsetHeight;
      toggleSubmenu(button, submenu, ulWrapper);
    });
  });

  const toggleSubmenu = (button, submenu, ulWrapper) => {
    if (button.getAttribute('aria-expanded') === 'false') {
      openSubmenu(button, submenu, ulWrapper);
    } else {
      closeSubmenu(button, submenu, ulWrapper);
    }
  };

  const openSubmenu = (button, submenu, ulWrapper) => {
    const activeButton = document.querySelector(".menu-button[aria-expanded='true']");
    if(activeButton) {
      const activeSubmenu = document.querySelector(".submenu-active");
      const activeWrapper = activeSubmenu.querySelector(".ul-wrapper");
      closeSubmenu(activeButton, activeSubmenu, activeWrapper);
    }

    submenu.classList.add('submenu-active');
    submenu.removeAttribute('inert');
    button.setAttribute('aria-expanded', 'true');
    ulWrapper.style.minHeight = `calc(100vh - ${headerHeight}px)`;
    submenu.style.height = `${ulWrapper.scrollHeight}px`;
    submenu.style.zIndex = 60;

    submenu.addEventListener('transitionend', () => {
      if (button.getAttribute('aria-expanded') === 'false') return;
      submenu.style.height = 'auto';
      submenu.style.zIndex = 50;
    }, {once: true});

    submenu.querySelectorAll('a').forEach((link) => {
      link.addEventListener('click', () => {
        closeSubmenu(button, submenu, ulWrapper);
      });
    });
  };

  const closeSubmenu = (button, submenu, ulWrapper) => {
    submenu.classList.remove('submenu-active');
    submenu.setAttribute('inert', 'true');
    button.setAttribute('aria-expanded', 'false');
    submenu.style.height = `${ulWrapper.scrollHeight}px`;

    setTimeout(() => {
      submenu.style.height = '0px';
    }, 1);
  };
}
