export default function () {
  const cardsWrapper = document.querySelector(".cards-wrapper");
  if (!cardsWrapper) return;

  const cards = cardsWrapper.querySelectorAll(".card");
  const steps = cardsWrapper.querySelectorAll(`.step`);
  const tagsWrapper = cardsWrapper.querySelector(".card-tags-wrapper");
  const tagsWrapperHeight = tagsWrapper ? tagsWrapper.offsetHeight : 30;
  const stepsWrapper = cardsWrapper.querySelector('.steps-wrapper');

  cardsWrapper.style.paddingBottom = `${Math.max(tagsWrapperHeight * (cards.length -1))}px`;

  cards.forEach((card, i) => {
    card.style.height = `calc(100vh - ${tagsWrapperHeight * (cards.length -1)}px)`;
    card.style.transform = `translateY(${i * tagsWrapperHeight}px)`;
  });

  const observer = new IntersectionObserver(observerCallback, {
    threshold: [0.4, 0.45, 0.5, 0.55, 0.6]
  });

  function observerCallback() {
    let activeIndex = 1;

    cards.forEach((card, index) => {
      if (card.getBoundingClientRect().top <= (window.innerHeight / 2)) {
        activeIndex = index + 1;
      }
    });

    steps.forEach(step => {
      step.classList.remove('active');
    });

    steps[activeIndex - 1].classList.add('active');
  }

  if (stepsWrapper) {
    cards.forEach(card => {
      observer.observe(card);
    });
  }
}
