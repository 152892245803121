import axios from 'axios';

export default function () {

  let productTypeSelect = document.querySelector('[name="fields[productType]"]');
  let brandSelect = document.querySelector('[name="fields[brand]"]');
  let yearSelect = document.querySelector('[name="fields[year]"]');
  let modelSelect = document.querySelector('[name="fields[model]"]');

  if (!productTypeSelect || !brandSelect || !yearSelect || !modelSelect) return;

  const categories = {
    ProductType: {
      select: productTypeSelect,
      value: null,
    },
    Brand: {
      select: brandSelect,
      value: null,
    },
    Year: {
      select: yearSelect,
      value: null,
    },
    Model: {
      select: modelSelect,
      value: null,
    },
  };

  const createSelect = (select, tags) => {
    let optionsHtml = '<option value=""></option>';
    tags.forEach((tag) => {
      optionsHtml += `<option value="${tag.TagName}" data-id="${tag.ID}" ${tags.length === 1 ? "selected" : ""}>${tag.TagName}</option>`;
    });
    select.innerHTML = optionsHtml;
  };

  const searchProducts = async () => {
    const url = new URL(window.apiProductSearch);
    for (const [key, cat] of Object.entries(categories)) {
      if (!cat.value) continue;
      url.searchParams.set(key, cat.value);
    }
    const res = await axios.get(url.toString());
    const apiCategories = res.data.Categories;
    for (const [key, cat] of Object.entries(categories)) {
      const apiCategory = apiCategories.find(c => c.CategoryName === key);
      if (apiCategory) {
        cat.select.removeAttribute('disabled');
        if (!cat.value) {
          createSelect(cat.select, apiCategory.Tags);
        }
      } else {
        cat.select.setAttribute('disabled', 'disabled');
      }
    }
  };

  searchProducts();

  const clearSelectsAfterIndex = (index) => {
    for (const [key, cat] of Object.entries(categories)) {
      const i = Object.keys(categories).indexOf(key);
      if (i > index) {
        cat.value = null;
        cat.select.value = '';
        cat.select.innerHTML = '';
      }
    }
  };

  for (const [key, cat] of Object.entries(categories)) {
    cat.select.addEventListener('change', () => {
      cat.value = cat.select.options[cat.select.selectedIndex].dataset.id ?? null;
      clearSelectsAfterIndex(Object.keys(categories).indexOf(key));
      searchProducts();
    });
  }
}
