export default () => ({
  open: false,
  panel: '',
  init() {
    this.$watch('open', () => {
      document.body.classList.toggle('overflow-clip', this.open);
      document.documentElement.classList.toggle('overflow-clip');
      document.querySelector('.site-content').classList.toggle('overflow-clip');

      if (!this.open) {
        this.panel = '';
      }
    });
  },
  toggleMenu() {
    return {
      ['@click']() {
        this.open = !this.open;
      }
    };
  },
  openPanel(id) {
    return {
      ['@click']() {
        this.panel = id;
      }
    };
  },
  back() {
    return {
      ['@click']() {
        this.panel = '';
      }
    };
  },
  openSearch() {
    return {
      async ['@click']() {
        this.panel = 'searchMobile';

        await this.$nextTick();
        document.getElementById('header-mobile-searchform-input').focus();
      }
    };
  },
  closeSearch() {
    return {
      ['@click']() {
        this.panel = '';
      }
    };
  }
});
