export default function siteSwitcherSelect() {
  const siteSwitcherSelect = document.getElementById('siteSwitcherSelect');

  if (!siteSwitcherSelect) return;

  siteSwitcherSelect.addEventListener('change', (event) => {
    const url = event.target.value;
    window.location.href = url;
  });
}
