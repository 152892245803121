export default () => ({
  openAutocomplete: false,
  query: "",
  results: [],
  async fetchResults (){
    if (this.query.length < 3) {
      this.results = [];
      return;
    }
    try {
      const source = await fetch(`/api/search?q=${this.query}`);
      const data = await source.json();
      this.results = data.data;
    } catch {
      this.results = [];
    }
  }
});
