<template>
  <div class="step-1">
    <div id="panel-title" class="h4 mr-10">{{ getGlobals.firstPanel.title }}</div>
    <div class="mt-10">
      <img :src="getGlobals.firstPanel.imgUrl" alt="">
      <div class="my-8 wysiwyg wysiwyg-white text-sm" v-html="getGlobals.firstPanel.body"></div>
      <a :href="getGlobals.firstPanel.linkUrl" class="arrow-link arrow-link-icon-gray arrow-link-text-white"><span>{{ getLabels.learnMore }}</span></a>
      <div class="mt-10 bg-gray-dark p-6">
        <div class="h3" v-html="getGlobals.firstPanel.inputBoxTitle?.split('\n').map(s => `<span class='last:text-cloud'>${s}</span>`).join('<br>')"></div>
        <div class="text-sm mt-3 mb-6" v-html="getGlobals.firstPanel.inputBoxText"></div>
        <div class="relative">
          <InputText
            v-model="serialNumber"
            :invalid="serialNumber.length > 0 && isSerialNumber !== true"
            :placeholder="getLabels.serialNumberLabel"
            :aria-label="getLabels.serialNumberLabel"
            name="serialNumber"
            maxlength="11"
            fluid
          />
          <div class="absolute top-1/2 -translate-y-1/2 right-3" ref="inputTip" :data-tippy-content="getGlobals.firstPanel.inputInfo">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Information">
              <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M12.8822 3.0433C14.9587 3.24792 16.8957 4.16711 18.3526 5.63937C19.9398 7.22812 20.8783 9.33682 20.989 11.5635C21.0997 13.7902 20.3749 15.9792 18.9529 17.7132C17.645 19.3151 15.8096 20.4157 13.7646 20.8243C11.7197 21.2329 9.59418 20.9238 7.75625 19.9505C5.91439 18.9562 4.47652 17.3668 3.68475 15.4501C2.88945 13.5236 2.78359 11.3877 3.38461 9.39395C3.98434 7.408 5.26109 5.68674 6.99937 4.52071C8.72304 3.36207 10.8059 2.83897 12.8822 3.0433ZM13.4981 19.5648C15.2501 19.2135 16.8234 18.2725 17.9481 16.9031C19.1651 15.4128 19.7843 13.534 19.688 11.6234C19.5918 9.71291 18.7869 7.90372 17.4261 6.53944C16.1797 5.28578 14.5257 4.50363 12.7534 4.3297C10.981 4.15578 9.20303 4.60114 7.73015 5.58794C6.6215 6.34065 5.72624 7.35979 5.12919 8.54882C4.53214 9.73785 4.25306 11.0574 4.31841 12.3824C4.38375 13.7075 4.79135 14.9941 5.50258 16.1205C6.21382 17.2469 7.20514 18.1757 8.38264 18.819C9.94606 19.6516 11.757 19.9156 13.4981 19.5648ZM11.3253 9.43253H12.9566V8.14671H11.3253V9.43253ZM12.9566 10.7183V15.8616H11.3253V10.7183H12.9566Z" fill="black"/>
              </g>
            </svg>
          </div>
        </div>
        <div v-if="serialNumber.length > 0 && isSerialNumber !== true" class="mt-2 text-sm text-red">
          {{ isSerialNumber }}
        </div>
        <div class="mt-4">
          <navigation-next-step/>
        </div>
      </div>
    </div>
    <div class="o-wysiwyg md:text-10" v-if="showMessageNotEligible">
      {{ getLabels.errorNotEligible }}
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import InputText from 'primevue/inputtext';
import NavigationNextStep from './NavigationNextStep.vue';
import tippy from 'tippy.js';

const { mapGetters, mapMutations } = createNamespacedHelpers('transferKitAssistant');

export default {
  components: {
    InputText,
    NavigationNextStep
  },
  data: () => ({
    showMessageNotEligible: false
  }),
  computed: {
    ...mapGetters([
      'getLabels',
      'getGlobals',
      'getSerialNumber',
      'getSerialNumberData',
      'getFormStepSerialNumberValidation',
      'getSerialNumberSelected'
    ]),
    formIsValid: {
      get() {
        return this.getFormStepSerialNumberValidation;
      },
      set(value) {
        this.setFormStepSerialNumberValidation(value);
      }
    },
    serialNumber: {
      get() {
        return this.getSerialNumber;
      },
      set(value) {
        this.setSerialNumber(value);
      }
    },
    isSerialNumber: {
      get() {
        let serialNumberFind = null;
        let valueFirst6 = this.serialNumber.slice(0, 6).toLowerCase();

        this.showMessageNotEligible = false;

        if (this.serialNumber.length !== 11) {
          return this.getLabels.errorSerialNumber11digit;
        }

        serialNumberFind = this.getSerialNumberData.filter((item) => {
          return item.prefix.toLowerCase() === valueFirst6.toLowerCase();
        });

        if (serialNumberFind.length === 0) {
          return this.getLabels.errorSerialNumberInvalid;
        }
        serialNumberFind = serialNumberFind[0];

        this.setSerialNumberSelected(serialNumberFind);

        if (!this.getSerialNumberSelected.eligible) {
          this.showMessageNotEligible = true;
          return this.getLabels.errorSerialNumberNotTransferable;
        }

        return true;
      }
    }
  },
  watch: {
    serialNumber (newValue, oldValue) {
      this.setFormStepSerialNumberValidation(this.isSerialNumber === true);
    }
  },
  methods: {
    ...mapMutations([
      'setCurrentStep',
      'setSerialNumber',
      'setFormStepSerialNumberValidation',
      'setSerialNumberSelected',
      'setCamsoModel'
    ])
  },
  mounted () {
    tippy(this.$refs["inputTip"], {
      allowHTML: true,
      placement: 'top-start'
    });
  }
};
</script>
