<template>
  <button
    class="arrow-link arrow-link-icon-gray-back arrow-link-text-white"
    @click="formGoPrevStep()"
  >
    <span>{{ getLabels.back }}</span>
  </button>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations } = createNamespacedHelpers('transferKitAssistant');

export default {
  computed: {
    ...mapGetters([
      'getLabels',
      'getCurrentStep',
    ]),
  },
  methods: {
    ...mapMutations([
      'setCurrentStep',
    ]),
    formGoPrevStep () {
      this.setCurrentStep(this.getCurrentStep - 1);
    }
  }
};
</script>
