export default function() {
  const anchors = document.querySelectorAll('.anchor-nav-title');
  const anchorLinks = document.querySelectorAll('.anchor-nav-link');

  if(anchors.length < 1) return;

  anchorLinks[0].classList.add('active');

  window.addEventListener('scroll', () => {
    let current = null;
    let activeLink = null;

    anchors.forEach(anchor => {
      const anchorOffset = anchor.offsetTop;

      if(window.scrollY >= anchorOffset - (window.innerHeight * 0.3)) {
        current = anchor.getAttribute('id');
      }
    });

    anchorLinks.forEach(link => {
      if(current !== null) {
        link.classList.remove('active');
      }

      const href = link.getAttribute('href').substring(1);

      if(href === current) {
        activeLink = link;
        link.classList.add('active');
      }
    });
  });
}
