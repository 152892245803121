export default {
  select: {
    root: ({ props, state, parent }) => ({
      class: [
        // Display and Position
        'flex',
        'relative',
        // Shape
        { 'rounded-md': parent.instance.$name !== 'InputGroup' },
        { 'first:rounded-l-md rounded-none last:rounded-r-md': parent.instance.$name == 'InputGroup' },
        { 'border-0 border-y border-l last:border-r': parent.instance.$name == 'InputGroup' },
        { 'first:ml-0 ml-[-1px]': parent.instance.$name == 'InputGroup' && !props.showButtons },
        // Color and Background
        { 'bg-white': !props.disabled },
        'border',
        { 'border-gray': !props.invalid },
        // Invalid State
        'invalid:focus:ring-red-light',
        'invalid:hover:border-red',
        { 'border-red': props.invalid },
        // Transitions
        'transition-all',
        'duration-200',
        // States
        { 'hover:border-gray': !props.invalid },
        { 'outline-none outline-offset-0 ring-1 ring-primary-500 z-10': state.focused },
        // Misc
        'cursor-pointer',
        'select-none',
        { 'bg-gray-medium select-none pointer-events-none cursor-default': props.disabled }
      ]
    }),
    label: ({ props, parent }) => {
      var _a;
      return {
        class: [
          //Font
          'leading-[normal]',
          // Display
          'block',
          'flex-auto',
          // Color and Background
          'bg-transparent',
          'border-0',
          { 'text-black': props.modelValue != null, 'text-gray': props.modelValue == null },
          'placeholder:text-gray',
          // Sizing and Spacing
          'w-[1%]',
          'py-4 px-4',
          { 'pr-7': props.showClear },
          //Shape
          'rounded-none',
          // Transitions
          'transition',
          'duration-200',
          // States
          'focus:outline-none focus:shadow-none',
          // Filled State *for FloatLabel
          { filled: ((_a = parent.instance) == null ? void 0 : _a.$name) == 'FloatLabel' && props.modelValue !== null },
          // Misc
          'relative',
          'cursor-pointer',
          'overflow-hidden overflow-ellipsis',
          'whitespace-nowrap',
          'appearance-none'
        ]
      };
    },
    dropdown: {
      class: ['flex items-center justify-center', 'shrink-0', 'bg-transparent', 'text-gray', 'w-12', 'rounded-r-md']
    },
    overlay: {
      class: ['bg-white', 'text-black', 'border border-gray', 'rounded-md', 'shadow-md']
    },
    listContainer: {
      class: ['max-h-[200px]', 'overflow-auto']
    },
    list: {
      class: 'p-1 list-none m-0'
    },
    option: ({ context }) => ({
      class: [
        'relative',
        'flex items-center',
        // Font
        'leading-none',
        // Spacing
        'm-0 px-3 py-2',
        'first:mt-0 mt-[2px]',
        // Shape
        'border-0 rounded',
        // Colors
        {
          'text-black': !context.selected,
          'bg-gray-light': context.focused && !context.selected,
          'bg-highlight': context.selected
        },
        //States
        { 'hover:bg-gray-light': !context.focused && !context.selected },
        { 'hover:bg-highlight-emphasis': context.selected },
        { 'hover:text-black hover:bg-gray-light': context.focused && !context.selected },
        // Transition
        'transition-shadow duration-200',
        // Misc
        'cursor-pointer overflow-hidden whitespace-nowrap'
      ]
    }),
    optionGroup: {
      class: ['font-semibold', 'm-0 py-2 px-3', 'text-gray', 'cursor-auto']
    },
    optionCheckIcon: 'relative -ms-1.5 me-1.5 text-black w-4 h-4',
    optionBlankIcon: 'w-4 h-4',
    emptyMessage: {
      class: ['leading-none', 'py-2 px-3', 'text-black', 'bg-transparent']
    },
    header: {
      class: ['pt-2 px-2 pb-0', 'm-0', 'border-b-0', 'rounded-tl-md', 'rounded-tr-md', 'text-black', 'bg-white', 'border-gray']
    },
    clearIcon: {
      class: ['text-gray', 'absolute', 'top-1/2', 'right-12', '-mt-2']
    },
    loadingIcon: {
      class: 'text-gray animate-spin'
    },
    transition: {
      enterFromClass: 'opacity-0 scale-y-[0.8]',
      enterActiveClass: 'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
      leaveActiveClass: 'transition-opacity duration-100 ease-linear',
      leaveToClass: 'opacity-0'
    }
  },

  inputtext: {
    root: ({ props, context, parent }) => {
      var _a;
      return {
        class: [
          // Font
          'leading-none',
          // Flex
          { 'flex-1 w-[1%]': parent.instance.$name == 'InputGroup' },
          // Spacing
          'm-0',
          { 'w-full': props.fluid },
          // Size
          {
            'py-6 px-6': props.size == 'large',
            'py-2 px-2': props.size == 'small',
            'py-4 px-4': props.size == null
          },
          // Shape
          { 'rounded-md': parent.instance.$name !== 'InputGroup' },
          { 'first:rounded-l-md rounded-none last:rounded-r-md': parent.instance.$name == 'InputGroup' },
          { 'border-0 border-y border-l last:border-r': parent.instance.$name == 'InputGroup' },
          { 'first:ml-0 -ml-px': parent.instance.$name == 'InputGroup' && !props.showButtons },
          // Colors
          'text-black',
          'placeholder:text-gray',
          { 'bg-white': !context.disabled },
          'border',
          { 'border-gray': !props.invalid },
          // Invalid State
          'invalid:focus:ring-red-light',
          'invalid:hover:border-red',
          { 'border-red': props.invalid },
          // States
          {
            'hover:border-gray': !context.disabled && !props.invalid,
            'focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 focus:z-10': !context.disabled,
            'bg-gray-light select-none pointer-events-none cursor-default': context.disabled
          },
          // Filled State *for FloatLabel
          { filled: ((_a = parent.instance) == null ? void 0 : _a.$name) == 'FloatLabel' && context.filled },
          // Misc
          'appearance-none',
          'transition-colors duration-200'
        ]
      };
    }
  }
};
