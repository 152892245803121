import { createStore } from 'vuex';
import axios from 'axios';
import { capitalizeFirstLetter } from '../utils/string.js';

const getCategoriesByName = (categories, name) => {
  return categories.find(c => c.CategoryName === name)?.Tags ?? [];
};

const setCategorySearchParam = (url, paramName, category) => {
  if (category) {
    url.searchParams.set(paramName, category);
  }
};

export default createStore({
  state() {
    return {
      apiUrl: '',
      language: '',
      searchParams: null,
      categories: [],
      products: []
    };
  },
  getters: {
    categories: (state) => state.categories,
    products: (state) => state.products,
    searchParams: (state) => state.searchParams
  },
  mutations: {
    setApiUrl: (state, apiUrl) => {
      state.apiUrl = apiUrl;
    },
    setLanguage: (state, language) => {
      state.language = language;
    },
    setSearchParams: (state, searchParams) => {
      state.searchParams = searchParams;
    },
    setCategories: (state, categories) => {
      state.categories = categories;
    },
    setProducts: (state, products) => {
      state.products = products;
    }
  },
  actions: {
    updateCategory({ state, dispatch }, { category, value }) {
      state.categories.find((c) => c.name === category.name).value = value;
      if (category.onClear && category.onClear.length > 0) {
        category.onClear.forEach((cat) => {
          state.categories.find((c) => c.name === cat).value = null;
        });
      }
      dispatch('searchProducts');
    },
    initialiseCategories({ commit }, categories) {
      categories.forEach((c) => {
        c.value = c.initialValue || null;
        c.list = [];
      });
      commit('setCategories', categories);
    },
    setCategoriesFromUrl({ state }) {
      state.categories.forEach((c) => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get(c.name);
        if (myParam) {
          c.value = parseInt(myParam);
        }
      });
    },
    async searchProducts({ state, commit }) {
      const url = new URL(state.apiUrl);
      url.searchParams.set('lang', state.language);
      state.categories.forEach((c) => {
        setCategorySearchParam(url, c.name, c.value);
      });
      const res = await axios.get(url.toString());

      commit('setProducts', res.data.Products);

      state.categories.forEach((c) => {
        c.list = getCategoriesByName(res.data.Categories, capitalizeFirstLetter(c.name));
        if (c.list.length === 1) {
          c.value = c.list[0].ID;
        }
      });

      const currentUrl = new URL(`${location.protocol}//${location.host}${location.pathname}`);
      state.categories.forEach((c) => {
        setCategorySearchParam(currentUrl, c.name, c.value);
      });
      window.history.pushState({}, '', currentUrl);

      commit('setSearchParams', currentUrl.searchParams);
    },
    clearAllFields({ state, dispatch }) {
      state.categories.forEach(c => {
        c.value = null;
      });
      dispatch('searchProducts');
    }
  }
});
