export default function () {
  const tabContainer = document.querySelector('[data-tab-panels]');
  if (!tabContainer) return;

  const clearActive = () => {
    buttons.forEach(button => {
      button.classList.remove('active');
    });
    document.querySelectorAll('.tab-panel').forEach(panel => {
      panel.classList.remove('active');
    });
  };

  const buttons = tabContainer.querySelectorAll('[data-tab]');
  buttons.forEach(button => {
    button.addEventListener('click', (event) => {
      clearActive();
      event.currentTarget.classList.add('active');
      document.querySelector('#' + event.currentTarget.getAttribute('aria-controls')).classList.add('active');
    });
  });
}
