export default function () {
  document.querySelectorAll('.toggle-buttons[data-units]').forEach(el => {
    const toggleButtons = el.querySelectorAll('button');

    toggleButtons.forEach((button) => {
      button.addEventListener('click', (event) => {
        const unit = event.currentTarget.dataset.unit;
        event.preventDefault();

        toggleButtons.forEach((otherButton) => {
          otherButton.setAttribute('aria-pressed', otherButton === event.currentTarget ? 'true' : 'false');
        });

        document.querySelectorAll(`[data-${unit}]`).forEach((element) => {
          if (element.dataset[unit]) {
            element.innerText = element.dataset[unit];
          }
        });
      });
    });
  });
}
