export default function () {
  const countrySelect = document.querySelector('.fui-type-address-country select');
  if (!countrySelect) return;

  const firstCountryOption = countrySelect.querySelector('option:first-child');

  firstCountryOption .after(countrySelect.querySelector('option[value="US"]'));
  firstCountryOption .after(countrySelect.querySelector('option[value="CA"]'));

  const provincesSelect = document.querySelector('.fui-type-address-state select.canadian-provinces');
  const usSelect = document.querySelector('.fui-type-address-state select.us-states');
  const otherStateInput = document.querySelector('.fui-type-address-state input');
  const allStateInputs = [provincesSelect, usSelect, otherStateInput];

  const enableInput = (input) => {
    input.removeAttribute('disabled');
    input.classList.remove('hidden');
  }

  const disabledInput = (input) => {
    input.setAttribute('disabled', 'disabled');
    input.classList.add('hidden');
  }

  const disableAllInputs = (inputs) => {
    inputs.forEach((input) => {
      disabledInput(input);
    })
  }

  const onCountryChange = () => {
    disableAllInputs(allStateInputs);
    if (countrySelect.value === 'CA') {
      enableInput(provincesSelect);
    } else if (countrySelect.value === 'US') {
      enableInput(usSelect);
    } else {
      enableInput(otherStateInput);
    }
  }

  countrySelect.addEventListener('change', onCountryChange);
  onCountryChange();
}
