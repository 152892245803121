import Swiper from 'swiper';
import { A11y, Navigation, Scrollbar } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

import { A11yMessages } from '../utils/swiper.js';

export default function () {

  function ArticlesList() {
    const isScreenDesktop = window.matchMedia('(min-width:1024px)');
    const sliders = document.querySelectorAll('.swiper-articles-list');

    let currentViewport = null;
    let lastViewport = null;
    let sliderInstances = [];

    sliders.forEach((slider) => {
      const swiperArticlesList = new Swiper(slider, {
        a11y: A11yMessages(pageLang),
        width: 300,
        spaceBetween: 16,
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: false,
        },
        navigation: {
          nextEl: ".swiper-button-next-custom",
          prevEl: ".swiper-button-prev-custom"
        },
        modules: [Navigation, A11y, Scrollbar]
      });
      sliderInstances.push(swiperArticlesList);

    });

    function onChange() {
      if (isScreenDesktop.matches) {
        currentViewport = 'desktop';
        sliderInstances.forEach((slider) => {

          slider.disable();
          slider.el.querySelector('.swiper-wrapper').removeAttribute('style');
        });
      } else {
        currentViewport = 'mobile';
        sliderInstances.forEach((slider) => {
          slider.enable();
        });
      }

      lastViewport = currentViewport;
    }

    isScreenDesktop.addEventListener('change', () => {
      onChange();
    });
    onChange();
  }

  // Init
  // --------------------------------------------
  const pageLang = document.querySelector('html').getAttribute('lang') || 'en';

  const swiperDiscover = new Swiper('.discover-cards', {
    a11y: A11yMessages(pageLang),
    slidesPerView: 'auto',
    modules: [A11y]
  });

  const swiperProducts = new Swiper('.swiper-products', {
    a11y: A11yMessages(pageLang),
    slidesPerView: 1.2,
    spaceBetween: 16,
    modules: [Navigation, A11y, Scrollbar],
    scrollbar: {
      el: ".swiper-scrollbar",
      hide: false,
    },
    navigation: {
      nextEl: ".swiper-button-next-custom",
      prevEl: ".swiper-button-prev-custom"
    },
    breakpoints: {
      640: {
        slidesPerView: 1.5,
      },
      768: {
        slidesPerView: 2.2,
      },
      1024: {
        slidesPerView: 2.5,
      },
      1280: {
        slidesPerView: 3.5,
        centerInsufficientSlides: true,
      }
    },
  });

  const swiperSocialMedia = new Swiper('.swiper-social-media', {
    a11y: A11yMessages(pageLang),
    spaceBetween: 12,
    slidesPerView: 1.75,
    centeredSlides: false,
    roundLengths: true,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next-custom",
      prevEl: ".swiper-button-prev-custom"
    },
    breakpoints: {
      400: {
        slidesPerView: 3,
        spaceBetween: 16
      },
      1024: {
        slidesPerView: 4,
      },
      1280: {
        slidesPerView: 5,
      }
    },
    modules: [Navigation, A11y]
  });

  const swiperTestimonies = new Swiper('.swiper-testimonies', {
    a11y: A11yMessages(pageLang),
    spaceBetween: 16,
    scrollbar: {
      el: ".swiper-scrollbar",
      hide: false,
    },
    navigation: {
      nextEl: ".swiper-button-next-custom",
      prevEl: ".swiper-button-prev-custom"
    },
    slidesPerView: 'auto',
    breakpoints: {
      1024: {
        spaceBetween: 80,
      }
    },
    modules: [Navigation, A11y, Scrollbar]
  });

  ArticlesList();
}


