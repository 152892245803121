export default function () {
  const triggers = document.querySelectorAll('[data-dd-target]');

  triggers.forEach((trigger) => {
    const target = trigger.dataset.ddTarget;
    const dropdown = document.getElementById(target);

    trigger.addEventListener('click', () => {
      toggleDropdown(trigger, dropdown);
    });
  });

  const toggleDropdown = (trigger, dropdown) => {
    if(trigger.getAttribute('aria-expanded') == 'false') {
      openDropdown(trigger, dropdown);
    } else {
      closeDropdown(trigger, dropdown);
    }
  };

  const openDropdown = (trigger, dropdown) => {
    dropdown.classList.add('dropdown-menu--active');
    dropdown.removeAttribute('inert');
    trigger.setAttribute('aria-expanded', 'true');
    focusOut(trigger, dropdown);
  };

  const closeDropdown = (trigger, dropdown) => {
    dropdown.classList.remove('dropdown-menu--active');
    dropdown.setAttribute('inert', 'true');
    trigger.setAttribute('aria-expanded', 'false');
  };

  const focusOut = (trigger, dropdown) => {
    dropdown.addEventListener('focusout', function (e) {
      if (dropdown.contains(e.relatedTarget)) return;
      closeDropdown(trigger, dropdown);
    });

    document.addEventListener('click', (e) => {
      if (dropdown.contains(e.target) || trigger.contains(e.target)) return;
      closeDropdown(trigger, dropdown);
    });
  };
}
