<template>
  <div class="step-3">
    <navigation-previous-step/>
    <div id="panel-title" class="h4 mt-10">{{ getGlobals.thirdPanel.title }}</div>
    <img class="my-8" :src="getGlobals.thirdPanel.imgUrl" alt="">
    <div class="wysiwyg wysiwyg-white text-sm" v-html="getGlobals.thirdPanel.text"></div>
    <div class="mt-10 bg-gray-dark p-6">
      <Select
        class="mb-6"
        v-model="toBrandValue"
        show-clear
        :placeholder="getLabels.brand"
        name="currentBrand"
        :options="getBrandsData"
        :disabled="getBrandsData.length === 0"
        @update:modelValue="onChangeSelectBrand"
      >
      </Select>
      <Select
        class="mb-6"
        v-model="toYearValue"
        show-clear
        :placeholder="getLabels.year"
        name="currentYear"
        :options="getToYearsData"
        :disabled="getToYearsData.length === 0"
        @update:modelValue="onChangeSelectYear"
      >
      </Select>
      <Select
        class="mb-6"
        v-model="toModelValue"
        show-clear
        :placeholder="getLabels.model"
        name="currentModel"
        :options="getToModelsData"
        :disabled="getToModelsData.length === 0"
        @update:modelValue="onChangeSelectModel"
      >
      </Select>
      <a class="mb-6 inline-block link-underline link-underline-white"
         :href="getGlobals.thirdPanel.linkUrl"
         target="_blank">
        {{ getGlobals.thirdPanel.linkLabel }}
      </a>
      <navigation-next-step :label="getLabels.getTransferKit"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Select from 'primevue/select';
import { createNamespacedHelpers } from 'vuex';
import NavigationNextStep from './NavigationNextStep.vue';
import NavigationPreviousStep from './NavigationPreviousStep.vue';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('transferKitAssistant');

export default {
  components: {
    Select,
    NavigationNextStep,
    NavigationPreviousStep
  },
  data: () => ({}),
  computed: {
    ...mapGetters([
      'getLabels',
      'getGlobals',
      'getSerialNumberSelected',
      'getFormStepVehicleToValidation',
      'getVehicleType',
      'getCamsoModel',

      'getToBrandValue',
      'getBrandsData',

      'getToModelValue',
      'getToModelsData',

      'getToYearValue',
      'getToYearsData'
    ]),
    formIsValid: {
      get () {
        return this.getFormStepVehicleToValidation;
      },
      set (value) {
        this.setFormStepVehicleToValidation(value);
      }
    },
    toBrandValue: {
      get () {
        return this.getToBrandValue;
      },
      set (value) {
        this.setToBrandValue(value);
      }
    },
    toModelValue: {
      get () {
        return this.getToModelValue;
      },
      set (value) {
        this.setToModelValue(value);
      }
    },
    toYearValue: {
      get () {
        return this.getToYearValue;
      },
      set (value) {
        this.setToYearValue(value);
      }
    }
  },
  methods: {
    ...mapMutations([
      'setCurrentStep',
      'setFormStepVehicleToValidation',
      'setSerialNumberSelected',

      'setToBrandValue',

      'setToModelValue',
      'setToModelsData',

      'setToYearValue',
      'setToYearsData',
    ]),
    ...mapActions(['updateBrandsFromApi']),
    setFormValidation () {
      this.setFormStepVehicleToValidation(!!this.getToBrandValue && !!this.getToYearValue && !!this.getToModelValue);
    },
    async onChangeSelectBrand (value) {
      this.setFormValidation();
      const url = new URL(`${window.location.origin}/actions/website/products/vehicles`);

      url.searchParams.set('brand', value);
      url.searchParams.set('vehicleType', this.getVehicleType);
      url.searchParams.set('camsoModel', this.getCamsoModel);

      try {
        const res = await axios.get(url);

        if (res.data.lookups.models.length === 0) {
          console.log('model No Data');
        }

        this.setToYearValue('');
        this.setToYearsData(res.data.lookups.years);

        this.setToModelValue('');
        this.setToModelsData(res.data.lookups.models);
      } catch (error) {
        console.log('error:', error);
      }
    },

    async onChangeSelectYear (value) {
      this.setFormValidation();
      const url = new URL(`${window.location.origin}/actions/website/products/vehicles`);

      url.searchParams.set('brand', this.getToBrandValue);
      url.searchParams.set('year', value);
      url.searchParams.set('vehicleType', this.getVehicleType);
      url.searchParams.set('camsoModel', this.getCamsoModel);

      try {
        const res = await axios.get(url);

        if (res.data.lookups.models.length === 0) {
          console.log('years No Data');
        }

        this.setToModelsData(res.data.lookups.models);

        if (!res.data.lookups.models.includes(this.getToModelValue)) {
          this.setToModelValue('');
        }
      } catch (error) {
        console.log('error:', error);
      }
    },

    async onChangeSelectModel (value) {
      this.setFormValidation();
      const url = new URL(`${window.location.origin}/actions/website/products/vehicles`);

      url.searchParams.set('brand', this.getToBrandValue);
      url.searchParams.set('model', value);
      url.searchParams.set('vehicleType', this.getVehicleType);
      url.searchParams.set('camsoModel', this.getCamsoModel);

      try {
        const res = await axios.get(url);

        if (res.data.lookups.years.length === 0) {
          console.log('years No Data');
        }

        this.setToYearsData(res.data.lookups.years);

        if (!res.data.lookups.years.includes(this.getToYearValue)) {
          this.setToYearValue('');
        }
      } catch (error) {
        console.log('error:', error);
      }
    }
  },
  created () {
    this.updateBrandsFromApi({ paramVehicleType: this.getVehicleType });
  }
};
</script>
