import axios from 'axios';

const state = {
  labels: {},
  globals: {},
  lang: '',
  conversionKitData: [],
  serialNumberData: [],
  currentStep: 1,

  serialNumber: '',
  serialNumberSelected: {},
  formStepSerialNumberValidation: false,
  formStepVehicleFromValidation: false,
  formStepVehicleToValidation: false,
  vehicleType: '',
  brandsData: [],
  camsoModel: '',

  // Vehicle From
  fromBrandValue: '',
  fromYearValue: '',
  fromYearsData: [],
  fromModelValue: '',
  fromModelsData: [],

  // Vehicle To
  toBrandValue: '',
  toYearValue: '',
  toYearsData: [],
  toModelValue: '',
  toModelsData: [],
};

const getters = {
  getLabels: (state) => state.labels,
  getGlobals: (state) => state.globals,
  getLang: (state) => state.lang,
  getConversionKitData: (state) => state.conversionKitData,

  getSerialNumber: (state) => state.serialNumber,
  getSerialNumberData: (state) => state.serialNumberData,
  getCurrentStep: (state) => state.currentStep,
  getSerialNumberSelected: (state) => state.serialNumberSelected,
  getFormStepSerialNumberValidation: (state) => state.formStepSerialNumberValidation,
  getFormStepVehicleFromValidation: (state) => state.formStepVehicleFromValidation,
  getFormStepVehicleToValidation: (state) => state.formStepVehicleToValidation,
  getVehicleType: (state) => state.vehicleType,
  getBrandsData: (state) => state.brandsData,
  getCamsoModel: (state) => state.camsoModel,

  // Vehicle From
  getFromBrandValue: (state) => state.fromBrandValue,
  getFromYearValue: (state) => state.fromYearValue,
  getFromYearsData: (state) => state.fromYearsData,
  getFromModelValue: (state) => state.fromModelValue,
  getFromModelsData: (state) => state.fromModelsData,

  // Vehicle From
  getToBrandValue: (state) => state.toBrandValue,
  getToYearValue: (state) => state.toYearValue,
  getToYearsData: (state) => state.toYearsData,
  getToModelValue: (state) => state.toModelValue,
  getToModelsData: (state) => state.toModelsData,
};

const mutations = {
  setLabels: (state, labels) => {
    state.labels = labels;
  },
  setGlobals: (state, globals) => {
    state.globals = globals;
  },
  setLang: (state, lang) => {
    state.lang = lang;
  },
  setConversionKitData: (state, conversionKitData) => {
    state.conversionKitData = conversionKitData;
  },
  setSerialNumber: (state, serialNumber) => {
    state.serialNumber = serialNumber;
  },
  setSerialNumberData: (state, serialNumberData) => {
    state.serialNumberData = serialNumberData;
  },
  setCurrentStep: (state, currentStep) => {
    state.currentStep = currentStep;
  },
  setSerialNumberSelected: (state, serialNumberSelected) => {
    state.serialNumberSelected = serialNumberSelected;
  },
  setFormStepSerialNumberValidation: (state, formStepSerialNumberValidation) => {
    state.formStepSerialNumberValidation = formStepSerialNumberValidation;
  },
  setFormStepVehicleFromValidation: (state, formStepVehicleFromValidation) => {
    state.formStepVehicleFromValidation = formStepVehicleFromValidation;
  },
  setFormStepVehicleToValidation: (state, formStepVehicleToValidation) => {
    state.formStepVehicleToValidation = formStepVehicleToValidation;
  },
  setVehicleType: (state, vehicleType) => {
    state.vehicleType = vehicleType;
  },
  setBrandsData: (state, brandsData) => {
    state.brandsData = brandsData;
  },
  setCamsoModel: (state, camsoModel) => {
    state.camsoModel = camsoModel;
  },

  // Vehicle From
  setFromBrandValue: (state, fromBrandValue) => {
    state.fromBrandValue = fromBrandValue;
  },
  setFromYearValue: (state, fromYearValue) => {
    state.fromYearValue = fromYearValue;
  },
  setFromYearsData: (state, fromYearsData) => {
    state.fromYearsData = fromYearsData;
  },
  setFromModelValue: (state, fromModelValue) => {
    state.fromModelValue = fromModelValue;
  },
  setFromModelsData: (state, fromModelsData) => {
    state.fromModelsData = fromModelsData;
  },

  // Vehicle To
  setToBrandValue: (state, toBrandValue) => {
    state.toBrandValue = toBrandValue;
  },
  setToYearValue: (state, toYearValue) => {
    state.toYearValue = toYearValue;
  },
  setToYearsData: (state, toYearsData) => {
    state.toYearsData = toYearsData;
  },
  setToModelValue: (state, toModelValue) => {
    state.toModelValue = toModelValue;
  },
  setToModelsData: (state, toModelsData) => {
    state.toModelsData = toModelsData;
  },

  // reset
  resetState: (state) => {
    state.conversionKitData = [];
    state.serialNumberData = [];
    state.currentStep = 1;

    state.serialNumber = '';
    state.serialNumberSelected = {};
    state.camsoModel = '';
    state.formStepSerialNumberValidation = false;
    state.formStepVehicleFromValidation = false;
    state.formStepVehicleToValidation = false;
    state.vehicleType = '';
    state.brandsData = [];

    // Vehicle From
    state.fromBrandValue = '';
    state.fromYearValue = '';
    state.fromYearsData = [];
    state.fromModelValue = '';
    state.fromModelsData = [];

    // Vehicle To
    state.toBrandValue = '';
    state.toYearValue = '';
    state.toYearsData = [];
    state.toModelValue = '';
    state.toModelsData = [];
  },

  resetStepVehicleFrom: (state) => {
    state.fromBrandValue = '';
    state.fromYearValue = '';
    state.fromYearsData = [];
    state.fromModelValue = '';
    state.fromModelsData = [];
  },

  resetStepVehicleTo: (state) => {
    state.toBrandValue = '';
    state.toYearValue = '';
    state.toYearsData = [];
    state.toModelValue = '';
    state.toModelsData = [];
  }
};

const actions = {
  async updateSerialNumberFromApi ({ commit }) {
    const url = new URL(`${window.location.origin}/actions/website/products/serial-numbers`);

    try {
      const res = await axios.get(url);

      if (res.data.length === 0) {
        console.log('serialNumber No Data');
      }

      commit('setSerialNumberData', res.data);
    } catch (error) {
      console.log('error:', error);
    }

  },
  async updateBrandsFromApi ({ commit, state }, { paramVehicleType }) {
    const url = new URL(`${window.location.origin}/actions/website/products/vehicles`);

    url.searchParams.set('vehicleType', paramVehicleType);
    url.searchParams.set('camsoModel', state.camsoModel);

    try {
      const res = await axios.get(url);

      if (res.data.lookups.brands.length === 0) {
        console.log('brand No Data');
      }

      commit('setBrandsData', res.data.lookups.brands);
    } catch (error) {
      console.log('error:', error);
    }
  },
  updateCaseCamsoX4S ({ commit, state }) {
    let camsoModel = state.serialNumberSelected.referenceModel !== '' ? state.serialNumberSelected.referenceModel : state.serialNumberSelected.model;

    // Si cas X4S, on fait recherche sans type de 'Vehicle' et on force le model à Camso X4S (ATV), Camso X4S (UTV)
    if (state.serialNumberSelected.model.search('Camso X4S') > -1) {
      commit('setVehicleType', '');
      camsoModel = 'Camso X4S (ATV),Camso X4S (UTV)';
    }

    commit('setCamsoModel', camsoModel);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

