<template>
  <Panel ref="panel"
         aria-labelled-by="select-vehicle-title"
         :close-modal-text="labels.closeModal"
         event-type="SelectVehicle:showModal"
         @open="onOpen"
         @close="onClose">
    <h3 id="select-vehicle-title"
        class="h4">
      {{ labels.selectVehiclePanelTitle }}
    </h3>

    <div class="mt-10">
      <Select
        v-for="cat in categories.filter(c => c.vehicle)"
        class="mb-6"
        :disabled="cat.list.length === 0"
        show-clear
        :placeholder="labels[cat.name]"
        :model-value="cat.value"
        :options="cat.list"
        option-label="TagName"
        option-value="ID"
        @update:modelValue="onModelChange(cat, $event)"
      ></Select>
    </div>

    <a class="mt-2 link-underline link-underline-white"
       :href="labels.cantFindVehicleLink"
       target="_blank">
      {{ labels.cantFindVehicleLabel }}
    </a>

    <primary-button css-class="btn-primary-blue btn-full mt-8"
                    @click="onSelectVehicle"
                    :disabled="!isProductInResults"
                    :label="labels.selectVehiclePanelButton" />

    <div class="mt-10 p-4 bg-maple" v-if="!isProductInResults">
      <p class="text-lg font-bold flex items-center gap-2">
        <Warning/>
        <span v-text="labels.notCompatibleTitle"></span>
      </p>
      <p class="mt-1" v-html="labels.notCompatibleText"></p>
      <ul class="mt-6 flex flex-col gap-4">
        <li v-for="product in products">
          <a :href="product.url + '?' + searchParams.toString()"
             class="p-4 bg-white text-black flex items-center gap-4">
            <img class="w-[132px] max-w-[35%] h-auto" :src="product.image" alt="" />
            <div>
              <h3 class="uppercase text-lg">{{ product.title }}</h3>
              <div class="text-lg [&_sup]:text-[11px]" v-html="product.price"></div>
              <div class="card-tags-wrapper flex-wrap mt-1">
                <span class="card-tag" v-for="tag in product.tags">{{ tag }}</span>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </Panel>
</template>
<script setup>
import Panel from './Panel.vue';
import Select from 'primevue/select';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import PrimaryButton from './PrimaryButton.vue';
const props = defineProps(['labels', 'craftProducts', 'craftProductNumber']);
const emit = defineEmits(['selectVehicle', 'open', 'close']);
import Warning from '../../icons/warning.svg';

const store = useStore();

const categories = computed(() => store.getters.categories);
const panel = ref(null);

const currentProductNumbers = props.craftProductNumber?.split(",") || [];

const searchParams = computed(() => store.getters.searchParams);

const products = computed(() => {
  const productNumbers = store.getters.products.map((p) => p.ProductNumber);
  return props.craftProducts.filter((p) =>
    p.productNumber.split(',').some((productNumber) => productNumbers.includes(productNumber))
  );
});

const isProductInResults = computed(() => {
  return currentProductNumbers.length === 0 || store.getters.products.some(p => currentProductNumbers.includes(p.ProductNumber));
});

const onModelChange = (category, value) => {
  store.dispatch('updateCategory', { category, value });
}

const onSelectVehicle = () => {
  panel.value.closeModal();
  emit('selectVehicle');
}

const onOpen = () => {
  emit('open');
}

const onClose = () => {
  emit('close');
}

</script>
